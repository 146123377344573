<template>
  <v-btn class="ml-2" min-width="0" text to="/" exact>
    <v-icon color="secondary">mdi-view-dashboard</v-icon>
  </v-btn>
</template>

<script>
export default {
  name: "DefaultGoHome",
};
</script>
